export const API_BASE_URL = process.env.REACT_APP_BASE_URL;
export const USER_URL = `${API_BASE_URL}/user`;
export const BUSINESS_URL = `${API_BASE_URL}/business`;
export const ACTIVITY_URL = `${API_BASE_URL}/activity`;
export const COMMON_URL = `${API_BASE_URL}/common`;
export const CATEGORY_URL = `${API_BASE_URL}/category`;
export const SUB_CATEGORY_URL = `${API_BASE_URL}/subcategory`;
export const PAGE_URL = `${API_BASE_URL}/page`;
export const PHOTO_URL = `${API_BASE_URL}/photo`;
export const PRODUCT_URL = `${API_BASE_URL}/product`;
export const UPLOAD_URL = `${API_BASE_URL}/uploads`;
export const REVIEW_URL = `${API_BASE_URL}/review`;
export const LEAD_URL = `${API_BASE_URL}/lead`;
export const FORM_SUBMISSION_URL = `${API_BASE_URL}/formsubmission`;

export const API_VERSION = process.env.REACT_APP_API_VERSION;
export const GMAP_API_KEY = process.env.REACT_APP_GMAP_API_KEY;
export const POSTHOG_PROJECT_KEY = process.env.REACT_APP_POSTHOG_PROJECT_KEY;
export const POSTHOG_HOST = process.env.REACT_APP_POSTHOG_HOST;
