import React, { useState } from 'react';
import { Card, Typography, Row, Col, Button, Select, Space, Input } from 'antd';
import { trim } from 'lodash';
import './SearchCard.css';
import { getBreakPoint } from 'utils/layout';

const { Title } = Typography;

const SearchCard = ({ cities, categories, filterRefreshing }) => {
  const isMobile = !getBreakPoint().includes('md');
  const [city, setCity] = useState('All');
  const [category, setCategory] = useState('All');
  const [searchText, setSearchText] = useState(null);

  const onSearch = () => {
    let url = `/search?searchText=${trim(searchText)}`;
    if (city !== 'All') {
      url = `${url}&city=${city}`;
    }
    if (category !== 'All') {
      url = `${url}&category=${category}`;
    }
    window.location = url;
  };

  const categoryOptions = categories?.map(category => ({ label: category?.name, value: category?._id }));

  categoryOptions.unshift({ value: 'All', label: 'All Categories' });

  const cityOptions = cities?.map(city => ({ label: city?.name, value: city?.name }));

  cityOptions.unshift({ value: 'All', label: 'Any City' });

  return (
    <Card
      className="tic-search-card"
    >
      <Row justify="space-between" align="middle" className='tic-search-card-wrapper'>
          <Col span={24}>
            <Title level={3} className={`tic-search-title${isMobile ? ' tic-sm' : ''}`}>
              Find verified and vetted business<br />across your city
            </Title>
          </Col>
          <Col span={24}>
            <Space className={`tic-search-box${isMobile ? ' tic-sm' : ''}`}  direction={isMobile ? 'vertical' : 'horizontal'} size={0}>
              <Select
                loading={filterRefreshing}
                showSearch
                placeholder="City"
                filterOption={(input, option) =>
                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
                defaultValue='All'
                size="large"
                options={cityOptions}
                onSelect={(value) => setCity(value)}
                style={{ width: '100%' }}
              />
              <Select
                loading={filterRefreshing}
                showSearch
                placeholder="Category"
                filterOption={(input, option) =>
                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
                defaultValue='All'
                size="large"
                options={categoryOptions}
                onSelect={(value) => setCategory(value)}
                style={{ width: '100%' }}
              />
              <div className='tic-search-input-wrapper'>
                <Input type="text" size="large" placeholder='Search for "Equipments"' onPressEnter={onSearch} onChange={(e) => setSearchText(e.target.value)} />
              </div>
              <Button type="primary" size="large" className="tic-search-btn" onClick={onSearch} onSubmit={onSearch}>
                Search
              </Button>
            </Space>
          </Col>
      </Row>
    </Card>
  );
};

export default SearchCard;
