import React from 'react';
import { Layout } from 'antd';
import { Outlet } from 'react-router-dom';
import ErrorBoundary from 'components/Errors/ErrorBoundary';
import Header from 'components/Layout/Header/Header';
import Footer from 'components/Layout/Footer/Footer';
import ActivityBar from 'components/Layout/ActivityBar/ActivityBar';

const { Content } = Layout;
const MainLayout = ({ user }) => {
  return (
    <Layout>
      <ActivityBar user={user} />
      <Header />
      <Layout>
        <Content style={{ padding: '50px 8%', marginTop: 140 }}>
          <ErrorBoundary>
            <Outlet />
          </ErrorBoundary>
        </Content>
      </Layout>
      <Footer />
    </Layout>
  );
};

export default MainLayout;
