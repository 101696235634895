export const Colors = {
  primary: '#008bd2',
  primaryDark: '#15355e',
  secondary: '#c7a209',
  secondaryDark: '#468e00',
  paleYellow: '#ffbf04',
  backgroundGrey: '#f1f1f1',
  backgroundBlue: '#e5f3fa',
  textPrimary: '#3e3e3e',
  textSecondary: '#6e6e6e',
  textLight: '#dedede',
  textLightAlt: '#898989',
  white: '#ffffff',
  black: '#000000',
};
