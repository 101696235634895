import React from 'react';
import { Card, Typography, Row, Col, Image, Button, Avatar, Space, Rate, Divider } from 'antd';
import './ProductListingCard.css';
import { UPLOAD_URL } from 'constants/ApiConstants';
import { Link } from 'react-router-dom';

const { Title, Paragraph } = Typography;

const INR = new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR', maximumSignificantDigits: 3 });
const ProductListingCard = ({ listing, type }) => {
  const rating = listing?.business?.metadata?.totalRating ? listing?.business?.metadata?.totalRating / listing?.business?.metadata?.totalReviewers : 0;
  return (
    <Card
      className="tic-product-listing-card"
    >
      <Row justify="space-between" align="middle">
          {
            listing?.image && (
              <Col span={24}>
                <Image src={`${UPLOAD_URL}/${listing?.image}`} preview={false} width="100%" className="tic-product-listing-image" />
              </Col>
            )
          }
          <Col span={24}>
            <Title level={5} className="tic-product-listing-title">
              {listing?.name}
            </Title>
            <Paragraph className="tic-product-listing-address">
              {
                listing?.price && (
                  <div style={{ marginTop: 10 }}>
                    <Paragraph className="tic-product-listing-price"><span style={{ fontSize: 20 }}>{INR.format(listing.price || 0)}</span> / Piece</Paragraph>
                  </div>
                )
              }
            </Paragraph>
          </Col>
          <Col span={24}>
            <div>
              <Divider style={{ marginTop: 5, marginBottom: 20 }} />
              <Paragraph className="tic-product-listing-address">
                <Space>
                  <Image src={require('assets/images/icon-map-pin.png')} className='tic-product-listing-map-icon' preview={false} />
                  <span>
                    {listing?.business?.address?.city} - {listing?.business?.address?.state}
                  </span>
                </Space>
              </Paragraph>
              <Link to={`/business/${listing?.business?.slug}`}>
                <Space gap="middle" align="center" direction="horizontal" className="tic-product-listing-subtitle-container">
                  <Title level={5} className="tic-product-listing-subtitle">
                    <Avatar src={`${UPLOAD_URL}/${listing?.business?.logo}`} className='tic-product-listing-logo' size="small" />
                    {listing?.business?.name}
                  </Title>
                  <Space>
                    <Rate disabled defaultValue={rating > 0 ? 1 : 0} count={1} size="small" />
                    <span className="tic-product-listing-rating-desc">{rating}</span>
                  </Space>
                </Space>
              </Link>
            </div>
          </Col>
          <Col span={24}>
            <Link to={`/business/${listing?.business?.slug}`}>
              <Button type="primary" size="large" className="tic-product-listing-btn">
                {type === 'owned' ? 'View' : 'Enquire'}
              </Button>
            </Link>
          </Col>
      </Row>
    </Card>
  );
};

export default ProductListingCard;
