import React from 'react';
import { Card, Typography, Row, Col, Avatar, Space, Tag, Divider } from 'antd';
import './LeadCard.css';
import { format } from 'date-fns';

const { Title, Paragraph } = Typography;

const LeadCard = ({ lead }) => {
  return (
    <Card
      className="tic-lead-card"
    >
      <Row justify="space-between" align="middle">
          <Col span={24}>
            <Space>
              <Avatar src={lead?.user?.avatar} size="large" />
              <Space direction="vertical" size={0}>
                <Title level={5} className="tic-lead-name">{lead?.name}</Title>
                <Paragraph className="tic-lead-time">{format(new Date(lead?.createdAt), 'dd MMM yyyy hh:mm a')}</Paragraph>
              </Space>
            </Space>
          </Col>
          <Col span={24}>
            <Divider className="mt-3 mb-3" />
            <Paragraph className="tic-lead-desc">{lead?.enquiry}</Paragraph>
          </Col>
          {
            lead?.product && (
              <Col span={24} className="mt-2">
                Interested In <Tag color="blue">{lead?.product?.name}</Tag>
              </Col>
            )
          }
      </Row>
    </Card>
  );
};

export default LeadCard;
