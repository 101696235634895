import React from 'react';
import { Card, Typography, Row, Col, Avatar, Space, Rate, Divider, Tag } from 'antd';
import './ReviewCard.css';
import { format } from 'date-fns';

const { Title, Paragraph } = Typography;

const ReviewCard = ({ review }) => {
  return (
    <Card
      className="tic-review-card"
      bordered={false}
    >
      <Row justify="space-between" align="middle">
          <Col span={24}>
            <Rate defaultValue={review?.rating} /> {review?.status === 'Verified' && (<Tag color="green" className="ml-3">Verified</Tag>)}
          </Col>
          <Col span={24} className="mt-2">
            <Paragraph className="tic-review-desc">{review?.review}</Paragraph>
          </Col>
          <Col span={24} className="mt-4">
            <Space>
              <Avatar src={review?.user?.avatar} size="small" />
              <Space direction="vertical" size={0}>
                <Title level={5} className="tic-review-name">{review?.user?.name}</Title>
                <Paragraph className="tic-review-time">{format(new Date(review?.createdAt), 'dd MMM yyyy hh:mm a')}</Paragraph>
              </Space>
            </Space>
          </Col>
      </Row>
      <Divider />
    </Card>
  );
};

export default ReviewCard;
