import React from 'react';
import SearchListingComponent from './index';
import { useSearchParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

const SearchListingPage = () => {
  const [searchParams] = useSearchParams();
  const city = searchParams.get('city')?.split(',') ?? [];
  const searchText = searchParams.get('searchText');
  const category = searchParams.get('category')?.split(',') ?? [];
  const subCategories = searchParams.get('subcategory')?.split(',') || [];
  return (
    <>
      <Helmet>
        <title>Search Results | TN Industrial Connect</title>
        <meta name="description" content="Search results for businesses in Tamil Nadu on TN Industrial Connect. Find the businesses you are looking for with our comprehensive search feature." />
        <meta name="keywords" content="search businesses, find businesses, Tamil Nadu business search, TN Industrial Connect search results" />
      </Helmet>
      <SearchListingComponent
        city={city}
        searchText={searchText}
        category={category}
        subCategories={subCategories}
      />
    </>
  );
};

export default SearchListingPage;
