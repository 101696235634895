import React from 'react';
import { Col, Row, Space } from 'antd';
import './Footer.css';

const Footer = () => {
  return (
    <Row justify="space-between" className='tic-footer'>
      <Col>
          <Space align="center">
            &copy; 2024 @ TN Industrial Connect. A product of Bristal Industrial Development Creators Private Limited. All rights reserved.
          </Space>
      </Col>
    </Row>
  );
};

export default Footer;
