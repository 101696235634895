import React from 'react';
import { Card, Typography, Row, Col, Checkbox } from 'antd';
import './FilterCard.css';

const { Title } = Typography;

const FilterCard = ({ title, value, options, className, onChange }) => {
  return (
    <Card bordered={false} className={className}>
      <Row justify="space-between" align="middle">
          <Col span={24}>
            <Title level={5} className="tic-filter-title">{title}</Title>
          </Col>
          <Col span={24}>
            <Checkbox.Group
              className='tic-filter-check-group'
              options={options}
              value={value}
              onChange={onChange}
            />
          </Col>
      </Row>
    </Card>
  );
};

export default FilterCard;
