import React, { useEffect, useState } from 'react';
import SearchListingComponent from './index';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { CATEGORY_URL, SUB_CATEGORY_URL } from 'constants/ApiConstants';
import { Helmet } from 'react-helmet-async';

const IndexableListingPage = () => {
  const params = useParams();
  const city = params.city;
  const category = params.category;
  const subCategory = params.subCategory;

  const [fCategory, setFCategory] = useState(null);
  const [fSubCategory, setFSubCategory] = useState(null);

  useEffect(() => {
    onLoadData();
  }, []);

  const onLoadData = async () => {
    if (category) {
      const categoryRes = await axios.get(`${CATEGORY_URL}/${category}?type=slug`);
      if (categoryRes.data.status === 'success') {
        setFCategory(categoryRes.data.category);
      }
    }
    if (subCategory) {
      const subCategoryRes = await axios.get(`${SUB_CATEGORY_URL}/${subCategory}?type=slug`);
      if (subCategoryRes.data.status === 'success') {
        setFSubCategory(subCategoryRes.data.subcategory);
      }
    }
  };

  const getSEO = () => {
    if (fCategory && fSubCategory) {
      if (city) {
        return {
          title: `${fSubCategory?.seometadata?.title || fSubCategory?.name} | ${fCategory?.seometadata?.title || fCategory?.name} | ${city} | TN Industrial Connect`,
          description: `${fSubCategory?.seometadata?.description} under ${fCategory?.name} in ${city}`,
          keywords: fSubCategory?.seometadata?.keywords,
        };
      }
      return {
        title: `${fSubCategory?.seometadata?.title || fSubCategory?.name} | ${fCategory?.seometadata?.title || fCategory?.name} | TN Industrial Connect`,
        description: `${fSubCategory?.seometadata?.description} under ${fCategory?.name}`,
        keywords: fSubCategory?.seometadata?.keywords,
      };
    } else if (fCategory) {
      if (city) {
        return {
          title: `${fCategory?.seometadata?.title || fCategory?.name} | ${city} | TN Industrial Connect`,
          description: `${fCategory?.seometadata?.description} in ${city}`,
          keywords: fCategory?.seometadata?.keywords,
        };
      }
      return {
        title: `${fCategory?.seometadata?.title || fCategory?.name} | TN Industrial Connect`,
        description: `${fCategory?.seometadata?.description}`,
        keywords: fCategory?.seometadata?.keywords,
      };
    }
    return {
      title: `Verified Businesses in ${city} | TN Industrial Connect`,
      description: `Discover and connect with verified businesses in ${city}. Browse our directory to find reliable businesses and services in your local area`,
      keywords: `${city} businesses, India Businesses, Tamil Nadu businesses, verified businesses in ${city}, local businesses, TN Industrial Connect`,
    };
  };

  const seo = getSEO();
  return (
    <>
      <Helmet>
        <title>{seo?.title}</title>
        <meta name="description" content={seo.description} />
        <meta name="keywords" content={seo.keywords} />
      </Helmet>
      <SearchListingComponent
        city={city ? [city] : []}
        category={fCategory ? [fCategory._id] : []}
        subCategories={fSubCategory ? [fSubCategory._id] : []}
      />
    </>
  );
};

export default IndexableListingPage;
