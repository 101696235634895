import React from 'react';
import { Card, Typography, Space, Row, Col, Image } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import { FiArrowRight, FiChevronRight } from 'react-icons/fi';
import './CategoryCard.css';
import { UPLOAD_URL } from 'constants/ApiConstants';

const { Title } = Typography;

const CategoryCard = ({ category, showSubCategory }) => {
  const navigate = useNavigate();

  return (
    <Card
      onClick={() => {
        if (category.type === 'view-all') {
          return navigate('/categories');
        }
        if (!showSubCategory) {
          navigate(`/category/${category?.name}`);
        }
      }}
      className={`tic-category-card${showSubCategory ? ' subcategory' : ''}${category.type === 'view-all' ? ' tic-category-view-all' : ''}`}
    >
        <Row 
          onClick={() => {
            if (showSubCategory) {
              navigate(`/category/${category?.name}`);
            }
          }}
          justify="space-between" align="middle" className='tic-category-card-header'>
            <Col>
                <Image src={category?.type === 'view-all' ? require('assets/images/icon-category.png'): `${UPLOAD_URL}/${category?.image}`} preview={false} width={40} height={40} />
            </Col>
            <Col span={14}>
                <Space direction="vertical" size={0}>
                    <Title level={5} className="tic-category-title">{category?.name}</Title>
                    {/* <Paragraph className="tic-category-desc">{category?.insights?.businesses} Businesses</Paragraph> */}
                </Space>
            </Col>
            <Col>
                <FiArrowRight style={{ fontSize: 24 }} />
            </Col>
        </Row>
        {
          showSubCategory && (
            <Row>
              <Col span={24}>
                <div className='tic-category-subcategory-list'>
                  {
                    category?.subcategories?.map((subcategory) => (
                      <Link to={`/category/${category?.name}/${subcategory?.name}`} className='tic-category-subcategory-item'>
                        <FiChevronRight />
                        <span>{subcategory?.name}</span>
                      </Link>
                    ))
                  }
                </div>
              </Col>
            </Row>
          )
        }
    </Card>
  );
};

export default CategoryCard;
