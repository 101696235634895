import React from 'react';
import { Image } from 'antd';
import { UPLOAD_URL } from 'constants/ApiConstants';
import './PhotoCard.css';

const PhotoCard = ({ photo }) => {
  return (
    <div className='tic-photo-card'>
      <Image src={`${UPLOAD_URL}/${photo?.thumbnail}`} preview={{ src: `${UPLOAD_URL}/${photo?.image}` }} width='100%' height={260} />
    </div>
  );
};

export default PhotoCard;
