import React from 'react';
import { Card, Typography, Space, Row, Col, Image } from 'antd';
import { useNavigate } from 'react-router-dom';
import { FiArrowRight } from 'react-icons/fi';
import './LocationCard.css';

const { Title } = Typography;

const LocationCard = ({ location }) => {
  const navigate = useNavigate();

  return (
    <Card
      onClick={() => {
        if (location.type === 'view-all') {
          return navigate('/cities');
        }
        navigate(`/listing/${location?.name}`);
      }}
      className={`tic-location-card ${location.type === 'view-all' ? 'tic-location-view-all' : ''}`}
    >
        <Row justify="space-between" align="middle">
            <Col>
                <Image src={require('assets/images/icon-location.png')} preview={false} width={40} height={40} />
            </Col>
            <Col span={14}>
                <Space direction="vertical" size={0}>
                    <Title level={5} className="tic-location-title">{location?.name}</Title>
                    {/* <Paragraph className="tic-location-desc">{location?.businesses} Businesses</Paragraph> */}
                </Space>
            </Col>
            <Col>
                <FiArrowRight style={{ fontSize: 24 }} />
            </Col>
        </Row>
    </Card>
  );
};

export default LocationCard;
