import { Card, Col, Collapse, Empty, Pagination, Row, Space, Typography, message } from 'antd';
import axios from 'axios';
import FilterCard from 'components/Cards/FilterCard/FilterCard';
import SearchBar from 'components/Cards/SearchBar/SearchBar';
import { PRODUCT_URL, CATEGORY_URL, COMMON_URL } from 'constants/ApiConstants';
import React, { useEffect, useState } from 'react';
import './Search.css';
import { getBreakPoint } from 'utils/layout';
import ProductListingCard from 'components/Cards/ProductListingCard/ProductListingCard';
import { sortBy } from 'lodash';

const SearchListingComponent = (props) => {
  const isMobile = !getBreakPoint().includes('md');
  const [products, setProducts] = useState([]);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(25);
  const [cities, setCities] = useState([]);
  const [categories, setCategories] = useState([]);
  const [city, setCity] = useState(props?.city);
  const [category, setCategory] = useState(props?.category);
  const [subCategories, setSubCategories] = useState(props?.subCategories);
  const searchText = props?.searchText;
  const [count, setCount] = useState(0);
  const [filterRefreshing, setFilterRefreshing] = useState(false);
  const [refreshing, setRefreshing] = useState(false);

  useEffect(() => {
    setCity(props?.city);
    setCategory(props?.category);
    setSubCategories(props?.subCategories);
    console.log(props);
  }, [props]);

  useEffect(() => {
    onLoadFilters();
  }, []);

  useEffect(() => {
    onLoadProduct();
  }, [category, subCategories, page, limit]);

  const onLoadFilters = async () => {
    setFilterRefreshing(true);
    try {
      const res = await axios.get(`${CATEGORY_URL}?limit=500&insights=true`);
      const cityRes = await axios.get(`${COMMON_URL}/business/cities`);
      if (res.data.status === 'error') {
        message.error(res.data.error);
      } else if (cityRes.data.status === 'error') {
        message.error(cityRes.data.error);
      }  else {
        setCategories(res.data.categories);
        setCities(sortBy(cityRes.data.cities, 'name'));
      }
    } catch (error) {
      message.error(error.message);
    }
    setFilterRefreshing(false);
  };

  const onLoadProduct = async () => {
    setRefreshing(true);
    try {
      const xApiFilter = {};
      if (searchText && searchText.length > 0) {
        xApiFilter.search = [searchText];
      }
      if (city && city.length > 0) {
        xApiFilter.city = city;
      }
      if (category && category.length > 0) {
        xApiFilter.category = category;
      }
      if (subCategories && subCategories.length > 0) {
        xApiFilter.subCategories = subCategories;
      }
      const res = await axios.get(`${PRODUCT_URL}?page=${page}&limit=${limit}&populate=true`, {
        headers: {
          'X-Api-Filters': JSON.stringify(xApiFilter),
        }
      });

      if (res.data.status === 'error') {
        message.error(res.data.error);
      } else {
        setProducts(res.data.products);
        setCount(res.data.count);
      }
    } catch (error) {
      message.error(error.message);
    }
    setRefreshing(false);
  };

  const showing = ((page - 1) * limit) + (products?.length || 0);

  const MobileFriendlyFilter = ({children}) => {
    if (isMobile) {
      return <Collapse><Collapse.Panel key="filter" header="Filter By" style={{ width: '100%' }}>{children}</Collapse.Panel></Collapse>
    }
    return children;
  }
  return (
    <Row gutter={[48, 0]}>
        <Col xl={6} lg={8} sm={24} xs={24}>
          <MobileFriendlyFilter>
            <Card bordered={false} loading={filterRefreshing} bodyStyle={{ padding: 0 }}>
              <FilterCard
                title="Filter by Location"
                value={city}
                onChange={(city) => setCity(city)}
                options={cities?.map(city => ({ label: city?.name, value: city?.name }))}
              />
              <FilterCard
                title="Filter by Category"
                className="mt-3"
                value={category}
                onChange={(category) => setCategory(category)}
                options={categories?.map(category => ({ label: category?.name, value: category?._id }))}
              />
            </Card>
          </MobileFriendlyFilter>
        </Col>
        <Col xl={18} md={16} sm={24}>
          <div className="mt-3">
            <SearchBar city={city} category={category} defaultValue={searchText} />
          </div>
          <Card bordered={false} loading={refreshing} bodyStyle={{ padding: '20px 0' }}>
            <Space>
              <Typography.Paragraph className='tic-search-result-text'>
                Showing {showing ?? 0} out of {count ?? 0} result {searchText ? `for "${searchText}"` : ''} {city?.length > 0 ? `in "${city.join(', ')}"` : ''}
              </Typography.Paragraph>
            </Space>
            {
              products?.length === 0 && (
                <Empty description="Oops, unable to find any products for the given query" />
              )
            }
            <Row gutter={[24, 24]}>
              {
                products?.map((product) => (
                  <Col xl={8} lg={12} sm={24}>
                    <ProductListingCard listing={product} />
                  </Col>
                ))
              }
            </Row>
            {
              products?.length > 0 && (
                <Pagination
                  className="mt-5"
                  current={page}
                  total={count}
                  pageSize={limit}
                  onChange={(page, pageSize) => {
                    setPage(page);
                    setLimit(pageSize);
                  }}
                  responsive
                />
              )
            }
          </Card>
        </Col>
    </Row>
  );
};

export default SearchListingComponent;
