import { Col, Row, Tabs, message, List, Pagination, Typography, Button, Tooltip } from 'antd';
import axios from 'axios';
import AboutCard from 'components/Cards/AboutCard/AboutCard';
import LeadCard from 'components/Cards/LeadCard/LeadCard';
import PhotoCard from 'components/Cards/PhotoCard/PhotoCard';
import ProductCard from 'components/Cards/ProductCard/ProductCard';
import ReviewCard from 'components/Cards/ReviewCard/ReviewCard';
import { PageNotFound } from 'components/Errors';
import PostReviewModal from 'components/Modals/PostReviewModal/PostReviewModal';
import SendEnquiryModal from 'components/Modals/SendEnquiryModal/SendEnquiryModal';
import { BUSINESS_URL, PRODUCT_URL, PHOTO_URL, LEAD_URL, REVIEW_URL } from 'constants/ApiConstants';
import React, { useEffect, useState } from 'react';
import { FiEdit } from 'react-icons/fi';
import { useParams } from 'react-router-dom';
import { getBreakPoint } from 'utils/layout';

const ViewListingPage = ({ user }) => {
  const isTablet = !getBreakPoint().includes('xl');
  const isMobile = !getBreakPoint().includes('sm');
  const {slug} = useParams();
  const [business, setBusiness] = useState(null);
  const [leads, setLeads] = useState([]);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(25);
  const [count, setCount] = useState(0);
  const [products, setProducts] = useState([]);
  const [product, setProduct] = useState(null);
  const [photos, setPhotos] = useState([]);
  const [reviews, setReviews] = useState([]);
  const [refreshing, setRefreshing] = useState(true);
  const [leadRefreshing, setLeadRefreshing] = useState(true);
  const [photoRefreshing, setPhotoRefreshing] = useState(true);
  const [productRefreshing, setProductRefreshing] = useState(true);
  const [reviewRefreshing, setReviewRefreshing] = useState(true);
  const [unlocked, setUnlocked] = useState(false);
  const [enquiryVisible, setEnquiryVisible] = useState(false);
  const [reviewVisible, setReviewVisible] = useState(false);
  const [activeKey, setActiveKey] = useState('1');
  const entityOwner = (user?.entityOwnership.filter((b) => b?.business?._id === business?._id))?.length > 0;

  const onStartEnquiry = (product) => {
    setEnquiryVisible(true);
    setProduct(product);
  };

  const onCloseEnquiry = () => {
    setEnquiryVisible(false);
    setProduct(null);
  };

  const onSaveEnquiry = () => {
    setUnlocked(true);
    onCloseEnquiry();
  };

  useEffect(() => {
    onLoadBusiness();
  }, [slug]);

  useEffect(() => {
    if (business && user) {
      if (entityOwner) {
        onLoadLeads();
        setActiveKey('0');
      }
    }
  }, [business, user]);

  useEffect(() => {
    if (business) {
      onLoadPhotos();
      onLoadProducts();
      onLoadReviews();
    }
  }, [business]);

  const onLoadBusiness = async () => {
    setRefreshing(true);
    try {
      const res = await axios.get(`${BUSINESS_URL}/${slug}?populate=true&type=slug&activity=true`);
      if (res.data.status === 'error') {
        if (res.data.code === 1006) {
          setBusiness(null);
        } else {
          message.error(res.data.error);
        }
      } else {
        setBusiness(res.data.business);
      }
    } catch (error) {
      message.error(error.message);
    }
    setRefreshing(false);
  };

  const onLoadLeads = async () => {
    setLeadRefreshing(true);
    try {
      const leadRes = await axios.get(`${LEAD_URL}?populate=true`, {
        headers: {
          'X-API-Filters': JSON.stringify({ business: [business._id] }),
        },
      });

      if (leadRes.data.status === 'error') {
        message.error(leadRes.data.error);
      } else {
        setLeads(leadRes.data.leads);
        setCount(leadRes.data.count);
      }
    } catch (err) {
      message.error(err.message);
    }
    setLeadRefreshing(false);
  };

  const onLoadPhotos = async () => {
    setPhotoRefreshing(true);
    try {
      const photoRes = await axios.get(`${PHOTO_URL}`, {
        headers: {
          'X-API-Filters': JSON.stringify({ business: [business._id], status: ['Active'] }),
        },
      });

      if (photoRes.data.status === 'error') {
        message.error(photoRes.data.error);
      } else {
        setPhotos(photoRes.data.photos);
      }
    } catch (err) {
      message.error(err.message);
    }
    setPhotoRefreshing(false);
  };

  const onLoadProducts = async () => {
    setProductRefreshing(true);
    try {
      const productRes = await axios.get(`${PRODUCT_URL}`, {
        headers: {
          'X-API-Filters': JSON.stringify({ business: [business._id], status: ['Active'] }),
        },
      });

      if (productRes.data.status === 'error') {
        message.error(productRes.data.error);
      } else {
        setProducts(productRes.data.products);
      }
    } catch (err) {
      message.error(err.message);
    }
    setProductRefreshing(false);
  };

  const onLoadReviews = async () => {
    setReviewRefreshing(true);
    try {
      const reviewRes = await axios.get(`${REVIEW_URL}?populate=true`, {
        headers: {
          'X-API-Filters': JSON.stringify({ business: [business._id], status: ['Posted', 'Verified'] }),
        },
      });

      if (reviewRes.data.status === 'error') {
        message.error(reviewRes.data.error);
      } else {
        setReviews(reviewRes.data.reviews);
      }
    } catch (err) {
      message.error(err.message);
    }
    setReviewRefreshing(false);
  };

  const onOpenReview = () => {
    setReviewVisible(true);
  };

  const onSaveReview = () => {
    onCloseReview();
    onLoadReviews();
    onLoadBusiness();
  };

  const onCloseReview = () => {
    setReviewVisible(false);
  };

  const showing = ((page - 1) * limit) + (leads?.length || 0);
  if (!business && !refreshing) {
    return (<PageNotFound />);
  }

  return (
    <Row gutter={[48, 0]}>
        <Col xl={7} lg={10} sm={24}>
          <AboutCard
            userId={user?._id}
            loading={refreshing}
            listing={business}
            unlocked={unlocked}
            entityOwner={entityOwner}
            onEnquire={() => onStartEnquiry(null)}
          />
        </Col>
        <Col xl={17} lg={14} sm={24}>
          <Tabs activeKey={activeKey} className='tic-tabs' tabBarGutter={12} onChange={setActiveKey}>
            {
              entityOwner && (
                <Tabs.TabPane tab="My Leads" key="0">
                  <Typography.Paragraph className='tic-search-result-text'>
                    Showing {showing ?? 0} out of {count ?? 0} leads
                  </Typography.Paragraph>
                  <List
                    loading={leadRefreshing}
                    dataSource={leads}
                    grid={{ gutter: 24, column:  isMobile ? 1 : isTablet ? 2 : 3 }}
                    renderItem={(lead) => (
                      <List.Item>
                        <LeadCard lead={lead} />
                      </List.Item>
                    )}
                  />
                  {
                    leads?.length > 0 && (
                      <Pagination
                        className="mt-5"
                        current={page}
                        total={count}
                        pageSize={limit}
                        onChange={(page, pageSize) => {
                          setPage(page);
                          setLimit(pageSize);
                        }}
                        responsive
                      />
                    )
                  }
                </Tabs.TabPane>
              )
            }
            <Tabs.TabPane tab="Products" key="1">
              <List
                loading={productRefreshing}
                dataSource={products}
                grid={{ gutter: 24, column: isMobile ? 1 : isTablet ? 2 : 3 }}
                renderItem={(product) => (
                  <List.Item>
                    <ProductCard product={product} onEnquire={onStartEnquiry} />
                  </List.Item>
                )}
              />
            </Tabs.TabPane>
            <Tabs.TabPane tab="Photos" key="2">
              <List
                loading={photoRefreshing}
                dataSource={photos}
                grid={{ gutter: 24, column:  isMobile ? 1 : isTablet ? 2 : 3 }}
                renderItem={(photo) => (
                  <List.Item>
                    <PhotoCard photo={photo} />
                  </List.Item>
                )}
              />
            </Tabs.TabPane>
            <Tabs.TabPane tab="Reviews" key="3">
              {
                user ? (
                  <div className="d-flex justify-content-end">
                    <Button type="primary" onClick={onOpenReview}>
                      <div className="tic-btn-icon">
                        <FiEdit />
                        <span>Post Review</span>
                      </div>
                    </Button>
                  </div>
                ) : (
                  <div className="d-flex justify-content-end">
                    <Tooltip title="Please login / signup to post review">
                      <Button type="primary" disabled>
                        <div className="tic-btn-icon">
                          <FiEdit />
                          <span>Post Review</span>
                        </div>
                      </Button>
                    </Tooltip>
                  </div>
                )
              }
              <List
                loading={reviewRefreshing}
                dataSource={reviews}
                grid={{ gutter: 24, column: 1 }}
                renderItem={(review) => (
                  <List.Item>
                    <ReviewCard review={review} />
                  </List.Item>
                )}
              />
            </Tabs.TabPane>
          </Tabs>
          <SendEnquiryModal visible={enquiryVisible} listing={business} product={product} user={user} save={onSaveEnquiry} close={onCloseEnquiry} />
          <PostReviewModal visible={reviewVisible} listing={business} user={user} save={onSaveReview} close={onCloseReview} />
        </Col>
    </Row>
  );
};

export default ViewListingPage;
