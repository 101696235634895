import React, { useState } from 'react';
import { Avatar, Button, Col, Divider, Dropdown, Menu, Row, Space } from 'antd';
import { Link } from 'react-router-dom';
import { FiBookmark, FiChevronDown, FiLogIn, FiMail, FiMenu, FiUser, FiUserPlus } from 'react-icons/fi';
import './ActivityBar.css';
import Authentication from 'components/Modals/Authentication/Authentication';
import { clearAuth } from 'utils/auth';
import { getBreakPoint } from 'utils/layout';

const ActivityBar = ({ user }) => {
  const isMobile = !getBreakPoint().includes('md');
  const isLoggedIn = user !== null;
  const [authVisible, setAuthVisible] = useState(false);
  const [authType, setAuthType] = useState('login');

  const onAuthenticate = (type) => {
    setAuthType(type);
    setAuthVisible(true);
  };

  const onAuthClose = () => {
    setAuthVisible(false);
  };

  const onLogout = () => {
    clearAuth();
    setTimeout(() => {
      window.location.href = '/';
    }, 100)
  };

  const profileMenu = (
    <div className="nav-profile nav-dropdown">
      {
        isLoggedIn && (
          <>
            <div className="nav-profile-header">
              <div className="d-flex align-items-center">
                <div>
                  <Avatar size={28}>
                    {user?.name?.length > 0 ? user?.name?.[0] : ''}
                  </Avatar>
                </div>
                <div className="pl-3">
                  <h4>{user?.name}</h4>
                </div>
              </div>
            </div>
            <Divider className="mt-2 mb-2" />
          </>
        )
      }
      <div className="nav-profile-body">
        <Menu>
          {
            isMobile && (
              <>
                <Menu.Item key="home">
                  <a href="/">Home</a>
                </Menu.Item>
                <Menu.Item key="categories">
                  <a href="/categories">Categories</a>
                </Menu.Item>
                <Menu.Item key="about">
                  <a href="/about">About Us</a>
                </Menu.Item>
                <Menu.Item key="contact">
                  <a href="/contact">Contact Us</a>
                </Menu.Item>
                <Menu.Item key="post-listing">
                  <a href="/contact?type=post-listing">Post your Listing</a>
                </Menu.Item>
              </>
            )
          }
          {
            isLoggedIn && (
              <>
                <Menu.Item key="listings">
                  <a href="/me/listing">My Listings</a>
                </Menu.Item>
                <Menu.Item key="logout" onClick={onLogout}>
                  <span className="font-weight-normal">Logout</span>
                </Menu.Item>
              </>
            )
          }
        </Menu>
      </div>
    </div>
  );

  return (
    <Row justify="end" className='tic-activity-bar'>
      <Col>
        {
          isLoggedIn ? (
            <Space>
              <Link to="/me/saved/listing?tab=saved">
                <Button type="text" size="large">
                  <div className="tic-btn-icon">
                    <FiBookmark />
                    <span>Saved</span>
                  </div>
                </Button>
              </Link>
              <Divider type='vertical' style={{ margin: 0, opacity: 0 }}/>
              <Link to="/me/saved/listing?tab=enquired">
                <Button type="text" size="large">
                  <div className="tic-btn-icon">
                    <FiMail />
                    <span>Enquired</span>
                  </div>
                </Button>
              </Link>
              <Divider type='vertical' style={{ margin: 0, opacity: 0 }}/>
              <Dropdown placement="bottomRight" overlay={profileMenu} trigger={['click']}>
                <Button type="text" size="large">
                  <div className="tic-btn-icon">
                    {!isMobile ? <FiUser /> : <FiMenu />}
                    {!isMobile ? <span>{user?.name}</span> : <span>Menu</span>}
                    {!isMobile && <FiChevronDown />}
                  </div>
                </Button>
              </Dropdown>
            </Space>
          ) : (
            <Space>
              <Button type="text" size="large" onClick={() => onAuthenticate('login')}>
                <div className="tic-btn-icon">
                  <FiLogIn />
                  <span>Login</span>
                </div>
              </Button>
              <Divider type='vertical' style={{ margin: 0, opacity: 0 }}/>
              <Button type="text" size="large" onClick={() => onAuthenticate('signup')}>
                <div className="tic-btn-icon">
                  <FiUserPlus />
                  <span>Signup</span>
                </div>
              </Button>
              {
                isMobile && (
                  <Dropdown placement="bottomRight" overlay={profileMenu} trigger={['click']}>
                    <Button type="text" size="large">
                      <div className="tic-btn-icon">
                        <FiMenu />
                        <span>Menu</span>
                      </div>
                    </Button>
                  </Dropdown>
                )
              }
            </Space>
          )
        }
      </Col>
      <Authentication tab={authType} visible={authVisible} close={onAuthClose} />
    </Row>
  );
};

export default ActivityBar;
