import { Card, Col, Empty, Pagination, Row, Tabs, message } from 'antd';
import axios from 'axios';
import { ACTIVITY_URL, BUSINESS_URL } from 'constants/ApiConstants';
import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import ListingCard from 'components/Cards/ListingCard/ListingCard';
import './ViewAll.css';
import { map } from 'lodash';

const ViewAllListingPage = ({ type }) => {
  const [businesses, setBusinesses] = useState([]);
  const [searchParams] = useSearchParams();
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(24);
  const activeKey = searchParams.get('tab');
  const [activeTab, setActiveTab] = useState(activeKey || 'viewed');
  const [count, setCount] = useState(0);
  const [refreshing, setRefreshing] = useState(false);

  useEffect(() => {
    setActiveTab(activeKey);
  }, [activeKey]);

  useEffect(() => {
    onLoadBusiness();
  }, [page, limit, activeTab]);

  const onLoadBusiness = async () => {
    setRefreshing(true);
    try {
      if (type === 'owned' || activeTab === 'saved') {
        const xApiFilter = {};
        if (type === 'owned') {
          xApiFilter.me = [true];
        }
        const res = await axios.get(`${BUSINESS_URL}?page=${page}&limit=${limit}&populate=true${activeTab === 'saved' ? '&favourites=true' : ''}`, {
          headers: {
            'X-Api-Filters': JSON.stringify(xApiFilter),
          }
        });

        if (res.data.status === 'error') {
          message.error(res.data.error);
        } else {
          setBusinesses(res.data.businesses);
          setCount(res.data.count);
        }
      } else {
        const xApiFilter = {
          type: [activeTab === 'viewed' ? 'View' : 'Enquire'],
          user: ['me']
        };
        const res = await axios.get(`${ACTIVITY_URL}?page=${page}&limit=${limit}&populate=true`, {
          headers: {
            'X-Api-Filters': JSON.stringify(xApiFilter),
            'X-Api-Sort': JSON.stringify({ activityOn: 'desc' }),
          }
        });
        if (res.data.status === 'error') {
          message.error(res.data.error);
        } else {
          setBusinesses(map(res.data.activities, 'business'));
          setCount(res.data.count);
        }
      }
    } catch (error) {
      message.error(error.message);
    }
    setRefreshing(false);
  };

  const onChangeTab = (tab) => {
    setActiveTab(tab);
  };

  return (
    <Row gutter={[48, 0]}>
      <Col xl={24}>
        {
          type === 'saved' && (
            <Tabs activeKey={activeTab} onChange={onChangeTab} className='tic-tabs no-border' tabBarGutter={12}>
              <Tabs.TabPane tab="All Viewed" key="viewed">
                <h3 className='tic-listing-all-heading-title'>All Viewed Listings</h3>
              </Tabs.TabPane>
              <Tabs.TabPane tab="Saved" key="saved">
                <h3 className='tic-listing-all-heading-title'>Saved Listings</h3>
              </Tabs.TabPane>
              <Tabs.TabPane tab="Enquired" key="enquired">
                <h3 className='tic-listing-all-heading-title'>Enquired Listings</h3>
              </Tabs.TabPane>
            </Tabs>
          )
        }
        {
          type === 'owned' && (
            <h3 className='tic-listing-all-heading-title'>My Listings</h3>
          )
        }
        <Card bordered={false} loading={refreshing} bodyStyle={{ padding: '10px 0' }}>
          {
            businesses?.length === 0 && (
              <Empty description="Oops, no listings found" />
            )
          }
          <Row gutter={[24, 24]}>
            {
              businesses?.map((business) => (
                <Col xl={8} lg={12} sm={24}>
                  <ListingCard listing={business} type={type} />
                </Col>
              ))
            }
          </Row>
          {
            businesses?.length > 0 && (
              <Pagination
                className="mt-5"
                current={page}
                total={count}
                pageSize={limit}
                onChange={(page, pageSize) => {
                  setPage(page);
                  setLimit(pageSize);
                }}
                responsive
              />
            )
          }
        </Card>
      </Col>
    </Row>
  );
};

export default ViewAllListingPage;
