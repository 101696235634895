import React, { useEffect, useState } from 'react';
import { Typography, Row, Col, Button, Avatar, Form, Input, Modal, message, Alert, Space, Rate, Card } from 'antd';
import './PostReviewModal.css';
import { REVIEW_URL, UPLOAD_URL } from 'constants/ApiConstants';
import { motion } from 'framer-motion';
import axios from 'axios';

const { Title, Paragraph } = Typography;

const PostReviewModal = ({ listing, user, save, close, visible }) => {
  const [form] = Form.useForm();
  const [error, setError] = useState(null);
  const [refreshing, setRefreshing] = useState(false);
  const [selected, setSelected] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (visible && user) {
      onLoadSelected();
    }
  }, [visible]);

  const onLoadSelected = async () => {
    setRefreshing(true);
    try {
      const reviewRes = await axios.get(`${REVIEW_URL}?populate=true`, {
        headers: {
          'X-API-Filters': JSON.stringify({ user: [user._id], business: [listing._id], status: ['Posted', 'Verified'] }),
        },
      });

      if (reviewRes.data.status === 'error') {
        message.error(reviewRes.data.error);
      } else {
        setSelected(reviewRes.data.reviews.length > 0 ? reviewRes.data.reviews[0]: null);
      }
    } catch (err) {
      message.error(err.message);
    }
    setRefreshing(false);
  };

  useEffect(() => {
    if (!visible) {
      setError(null);
      setLoading(false);
      form.resetFields();
    } else {
      form.setFieldsValue({
        rating: selected?.rating,
        review: selected?.review
      })
    }
  }, [visible, selected]);


  const onSubmit = async (values) => {
    setLoading(true);
    setError(null);
    try {
      const data = {
        rating: values.rating,
        review: values.review,
      };

      let res;
      if (selected) {
        res = await axios.put(`${REVIEW_URL}/${selected._id}`, data);
      } else {
        data.business = listing._id;
        res = await axios.post(`${REVIEW_URL}`, data);
      }
      if (res.data.status === 'error') {
        setError(res.data.error);
        return setLoading(false);
      }
      message.success(`Successfully ${selected ? 'updated' : 'posted'} review for business`);
      save();
    } catch (err) {
      setError(err.message);
    }
    setLoading(false);
  };

  const onClose = () => {
    if (close) close();
  }

  return (
    <Modal open={visible} footer={null} onCancel={onClose} className="tic-review-modal-card">
      <Card bordered={false} loading={refreshing} style={{ padding: 0 }}>
        <Row justify="space-between" align="middle">
          <Col span={24}>
            <Paragraph className="tic-review-modal-subtitle">
              {selected ? 'Modify your Review' : 'Post your Review'} for
            </Paragraph>
            <Title level={5} className="tic-review-modal-title">
              <Space direction="horizontal" size={2}>
                <Avatar src={`${UPLOAD_URL}/${listing?.logo}`} className='tic-review-modal-logo' />
                <Space direction="vertical" size={2}>
                  {listing?.name}
                </Space>
              </Space>
            </Title>
            <Form form={form} layout="vertical" onFinish={onSubmit}>
              <Form.Item
                name="rating"
                rules={[
                  {
                    required: true,
                    message: 'Please select your rating',
                  },
                ]}
                hasFeedback>
                  <Rate />
              </Form.Item>
              <Form.Item
                name="review"
                rules={[
                  {
                    required: true,
                    type: 'string',
                    whitespace: true,
                    message: 'Please enter your review',
                  },
                ]}
                hasFeedback>
                <Input.TextArea size="small" placeholder="Type your review..." className='tic-review-modal-textarea' rows={4} />
              </Form.Item>
              <Form.Item>
                <Button size="large" type="primary" block htmlType="submit" className="tic-review-modal-btn" loading={loading}>
                  {selected ? 'Update' : 'Post'} Review
                </Button>
              </Form.Item>
              <motion.div
                initial={{ opacity: 0, marginBottom: 0 }}
                animate={{
                  opacity: error ? 1 : 0,
                  marginTop: error ? 20 : 0,
                  marginBottom: error ? 40 : 0,
                }}>
                <Alert type='error' showIcon message={error}></Alert>
              </motion.div>
            </Form>
          </Col>
        </Row>
      </Card>
    </Modal>
  );
};

export default PostReviewModal;
