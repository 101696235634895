import React, { useState } from 'react';
import { Button, Space, Input } from 'antd';
import { trim } from 'lodash';
import './SearchBar.css';

const SearchBar = ({ defaultValue, city, category }) => {
  const [searchText, setSearchText] = useState(defaultValue);

  const onSearch = () => {
    let url = `/search?searchText=${trim(searchText)}`;
    if (city?.length > 0) {
      url = `${url}&city=${city}`;
    }
    if (category?.length > 0) {
      url = `${url}&category=${category}`;
    }
    window.location = url;
  };

  return (
    <div className="tic-search-bar">
      <Space>
        <Input type="text" size="large" placeholder='Search for "Equipments"' value={searchText} onPressEnter={onSearch} onChange={(e) => setSearchText(e.target.value)} />
        <Button type="primary" size="large" className="tic-search-btn" onClick={onSearch} onSubmit={onSearch}>
          Search
        </Button>
      </Space>
    </div>
  );
};

export default SearchBar;
