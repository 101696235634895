import { Card, Col, Row, Typography, message } from 'antd';
import axios from 'axios';
import CategoryCard from 'components/Cards/CategoryCard/CategoryCard';
import ListingCard from 'components/Cards/ListingCard/ListingCard';
import LocationCard from 'components/Cards/LocationCard/LocationCard';
import SearchCard from 'components/Cards/SearchCard/SearchCard';
import { BUSINESS_URL, CATEGORY_URL, COMMON_URL } from 'constants/ApiConstants';
import { take } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';

const HomePage = ({ user }) => {
  const [filterRefreshing, setFilterRefreshing] = useState(false);
  const [bookmarkRefreshing, setBookmarkRefreshing] = useState(false);
  const [featuredRefreshing, setFeaturedRefreshing] = useState(false);
  const [cities, setCities] = useState([]);
  const [categories, setCategories] = useState([]);
  const [bookmarked, setBookmarked] = useState([]);
  const [featured, setFeatured] = useState([]);

  useEffect(() => {
    onLoadFilters();
    onLoadFeatured();
  }, []);

  useEffect(() => {
    if (user) {
      onLoadBookmarked();
    }
  }, [user]);

  const onLoadFilters = async () => {
    setFilterRefreshing(true);
    try {
      const res = await axios.get(`${CATEGORY_URL}?limit=500&insights=true`);
      const cityRes = await axios.get(`${COMMON_URL}/business/cities`);
      if (res.data.status === 'error') {
        message.error(res.data.error);
      } else if (cityRes.data.status === 'error') {
        message.error(cityRes.data.error);
      }  else {
        setCategories(res.data.categories);
        setCities(cityRes.data.cities);
      }
    } catch (error) {
      message.error(error.message);
    }
    setFilterRefreshing(false);
  };

  const onLoadBookmarked = async () => {
    setBookmarkRefreshing(true);
    try {
      const res = await axios.get(`${BUSINESS_URL}?page=1&limit=8&favourites=true`, {
        headers: {
          'X-Api-Sort': JSON.stringify({ createdAt: 'desc' }),
        }
      });
      if (res.data.status === 'error') {
        message.error(res.data.error);
      } else {
        setBookmarked(res.data.businesses);
      }
    } catch (error) {
      message.error(error.message);
    }
    setBookmarkRefreshing(false);
  };

  const onLoadFeatured = async () => {
    setFeaturedRefreshing(true);
    try {
      const res = await axios.get(`${BUSINESS_URL}?limit=16`, {
        headers: {
          'X-Api-Filters': JSON.stringify({ featured: [true] }),
          'X-Api-Sort': JSON.stringify({ featuredPriority: 'desc' }),
        }
      });
      if (res.data.status === 'error') {
        message.error(res.data.error);
      } else {
        setFeatured(res.data.businesses);
      }
    } catch (error) {
      message.error(error.message);
    }
    setFeaturedRefreshing(false);
  };

  return (
    <div>
      <Helmet>
        <title>TN Industrial Connect - Find Local Businesses and Listings in Your City</title>
        <meta name="description" content="Discover local businesses and listings in your city with TN Industrial Connect. Your go-to platform for connecting with local services, shops, and more." />
        <meta name="keywords" content="TN Industrial Connect, local businesses, city listings, business directory, local services, find businesses" />
      </Helmet>
      <SearchCard cities={cities} categories={categories} filterRefreshing={filterRefreshing} />
      <Card bordered={false} bodyStyle={{ padding: '25px 0', paddingTop: 50 }} loading={filterRefreshing}>
        <Typography.Title level={3}>View by Category</Typography.Title>
        <Row gutter={[24, 24]} className="mt-4">
          {take(categories, 7).map((category) => (
            <Col xl={6} lg={8} md={12} sm={24} xs={24}>
              <CategoryCard
                category={category}
              />
            </Col>
          ))}
          <Col xl={6} lg={8} md={12} sm={24} xs={24}>
            <CategoryCard category={{ name: 'View All Categories', type: 'view-all' }} />
          </Col>
        </Row>
      </Card>
      <Card bordered={false} bodyStyle={{ padding: '25px 0' }} loading={filterRefreshing}>
        <Typography.Title level={3}>View by City</Typography.Title>
        <Row gutter={[24, 24]} className="mt-4">
          {take(cities, 7).map((location) => (
            <Col xl={6} lg={8} md={12} sm={24} xs={24}>
              <LocationCard location={location} />
            </Col>
          ))}
          <Col xl={6} lg={8} md={12} sm={24} xs={24}>
            <LocationCard location={{ name: 'View All Cities', type: 'view-all' }} />
          </Col>
        </Row>
      </Card>
      <Card bordered={false} bodyStyle={{ padding: '25px 0' }} loading={featuredRefreshing}>
        <Typography.Title level={3}>Featured Listings</Typography.Title>
        <Row gutter={[24, 24]} className="mt-4">
          {featured.map((business) => (
            <Col lg={6}>
              <ListingCard listing={business} />
            </Col>
          ))}
        </Row>
      </Card>
      {
        user && (
          <Card bordered={false} bodyStyle={{ padding: '25px 0' }} loading={bookmarkRefreshing}>
            <Typography.Title level={3}>Bookmarked Listings</Typography.Title>
            <Row gutter={[24, 24]} className="mt-4">
              {bookmarked.map((business) => (
                <Col lg={6}>
                  <ListingCard listing={business} />
                </Col>
              ))}
            </Row>
          </Card>
        )
      }
    </div>
  );
};

export default HomePage;
