import { Card, message } from 'antd';
import axios from 'axios';
import { COMMON_URL } from 'constants/ApiConstants';
import React, { useEffect, useState } from 'react';
import './Cities.css';
import { Helmet } from 'react-helmet-async';
import CityCard from 'components/Cards/CityCard/CityCard';
import { groupBy, sortBy } from 'lodash';
import Masonry, {ResponsiveMasonry} from "react-responsive-masonry";

const CityPage = () => {
  const [cities, setCities] = useState([]);
  const [refreshing, setRefreshing] = useState(false);

  useEffect(() => {
    onLoadCity();
  }, []);

  const onLoadCity = async () => {
    setRefreshing(true);
    try {
      const res = await axios.get(`${COMMON_URL}/business/cities`);
      if (res.data.status === 'error') {
        message.error(res.data.error);
      } else {
        const _cities = sortBy(res.data.cities, 'name');
        const _citiesByTitle = groupBy(_cities, (city) => city?.name[0]?.toUpperCase());
        setCities(Object.keys(_citiesByTitle).map((title) => ({title, cities: _citiesByTitle[title]})));
      }
    } catch (error) {
      message.error(error.message);
    }
    setRefreshing(false);
  };

  return (
    <>
    <Helmet>
      <title>All Cities | TN Industrial Connect</title>
      <meta name="description" content="Explore all business cities on TN Industrial Connect. Find verified businesses across various industries and sectors in Tamil Nadu." />
      <meta name="keywords" content="Business cities, Tamil Nadu businesses, TN Industrial Connect Cities, Find businesses by city" />
    </Helmet>
    <Card bordered={false} loading={refreshing} bodyStyle={{ padding: '20px 0' }}>
      <h3 className='tic-city-heading-title'>Viewing All Cities</h3>
      <ResponsiveMasonry
          columnsCountBreakPoints={{350: 1, 750: 2, 900: 3}}
      >
          <Masonry>
            {
              cities?.map(({title, cities}) => (
                <CityCard title={title} cities={cities} />
              ))
            }
          </Masonry>
      </ResponsiveMasonry>
    </Card>
    </>
  );
};

export default CityPage;
