import React, { useEffect, useState } from 'react';
import { Button, Form, Input, Modal, Tabs, Alert, Image } from 'antd';
import ErrorBoundary from 'components/Errors/ErrorBoundary';
import { USER_URL } from 'constants/ApiConstants';
import axios from 'axios';
import { motion } from 'framer-motion';
import { FiLock, FiMail, FiPhone, FiUser } from 'react-icons/fi';
import './Authentication.css';
import { setAuth } from 'utils/auth';

const Authentication = ({ tab, visible, close }) => {
  const [form] = Form.useForm();
  const [activeTab, setActiveTab] = useState('login');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    setActiveTab(tab);
  }, [tab]);

  useEffect(() => {
    setLoading(false);
    setError(null);
  }, [activeTab]);

  const onSubmit = (values) => {
    if (activeTab === 'login') {
      onLogin(values);
    } else {
      onSignup(values);
    }
  };

  const onLogin = async (values) => {
    if (loading) return;
    setLoading(true);
    try {
      const res = await axios.post(`${USER_URL}/login`, {
        username: values.username,
        password: values.password,
        platform: 'Web',
      });
      if (res.data.status === 'error') {
        setError(res.data.error);
      } else {
        await setAuth({
          token: res.data.token,
          expiryTimeStamp: res.data.expiryTimeStamp,
          validity: res.data.validity,
          aclGroup: res.data.aclGroup,
          _id: res.data._id,
        });
        form.resetFields();
        close();
        window.location.reload();
      }
    } catch (err) {
      setError(err.message);
    }
    setLoading(false);
  };

  const onSignup = async (values) => {
    if (loading) return;
    setLoading(true);
    try {
      const res = await axios.post(`${USER_URL}`, {
        name: values.name,
        email: values.email,
        phone: values.phone,
        password: values.password,
        aclGroup: 'user',
        platform: 'Web',
      });
      if (res.data.status === 'error') {
        setError(res.data.error);
      } else {
        await setAuth({
          token: res.data.token,
          expiryTimeStamp: res.data.expiryTimeStamp,
          validity: res.data.validity,
          aclGroup: res.data.aclGroup,
          _id: res.data._id,
        });
        form.resetFields();
        close();
        window.location.reload();
      }
    } catch (err) {
      setError(err.message);
    }
    setLoading(false);
  };

  const onClose = () => {
    form.resetFields();
    close();
  };

  return (
    <Modal open={visible} footer={null} onCancel={onClose} className="tic-auth-modal-card" closable={false}>
      <ErrorBoundary>
        <Tabs className='tic-auth-tabs' tabBarGutter={0} activeKey={activeTab} onChange={setActiveTab} destroyInactiveTabPane>
          <Tabs.TabPane tab="Login" key="login">
            <Image src={require('assets/images/logo-sm.png')} height={90} preview={false} className='tic-auth-image' />
            <h3 className='tic-auth-title'>Login to continue</h3>
            <Form form={form} layout="vertical" onFinish={onSubmit}>
              <Form.Item
                name="username"
                rules={[
                  {
                    type: 'regex',
                    regex: /^(.+@.+|\d{10})$/,
                    message: 'Please enter a valid Email Address or Phone Number',
                  },
                  {
                    required: true,
                    message: 'Please enter your Email Address or Phone Number',
                  },
                ]}
                hasFeedback>
                <Input size="large" placeholder="Email Address / Phone Number" prefix={<FiMail className='tic-auth-icon' />} />
              </Form.Item>
              <Form.Item
                name="password"
                rules={[
                  {
                    required: true,
                    message: 'Please enter your Password',
                  },
                ]}
                hasFeedback>
                <Input size="large" type="password" placeholder="Password" prefix={<FiLock className='tic-auth-icon' />} />
              </Form.Item>

              <Form.Item>
                <Button size="large" type="primary" block htmlType="submit" className="tic-enquiry-modal-btn" loading={loading}>
                  Login
                </Button>
              </Form.Item>
              <motion.div
                initial={{ opacity: 0, marginBottom: 0 }}
                animate={{
                  opacity: error ? 1 : 0,
                  marginTop: error ? 20 : 0,
                  marginBottom: error ? 40 : 0,
                }}>
                <Alert type='error' showIcon message={error}></Alert>
              </motion.div>
            </Form>
          </Tabs.TabPane>
          <Tabs.TabPane tab="Signup" key="signup">
            <Image src={require('assets/images/logo-sm.png')} height={90} preview={false} className='tic-auth-image' />
            <h3 className='tic-auth-title'>Signup to continue</h3>
            <Form form={form} layout="vertical" onFinish={onSubmit}>
              <Form.Item
                name="name"
                rules={[
                  {
                    required: true,
                    message: 'Please enter your Full Name',
                  },
                ]}
                hasFeedback>
                <Input size="large" placeholder="Full Name" prefix={<FiUser className='tic-auth-icon' />} />
              </Form.Item>
              <Form.Item
                name="email"
                rules={[
                  {
                    type: 'email',
                    message: 'Please enter a valid Email Address',
                  },
                  {
                    required: true,
                    message: 'Please enter your Email Address',
                  },
                ]}
                hasFeedback>
                <Input size="large" placeholder="Email Address" prefix={<FiMail className='tic-auth-icon' />} />
              </Form.Item>
              <Form.Item
                name="phone"
                rules={[
                  {
                    type: 'regex',
                    regex: /^([+]\d{2})?\d{10}$/,
                    message: 'Please enter a valid Phone Number',
                  },
                  {
                    required: true,
                    message: 'Please enter your Phone Number',
                  },
                ]}
                hasFeedback>
                <Input size="large" placeholder="Phone Number" prefix={<FiPhone className='tic-auth-icon' />} />
              </Form.Item>
              <Form.Item
                name="password"
                rules={[
                  {
                    required: true,
                    message: 'Please enter your Password',
                  },
                ]}
                hasFeedback>
                <Input size="large" type="password" placeholder="Password" prefix={<FiLock className='tic-auth-icon' />} />
              </Form.Item>

              <Form.Item>
                <Button size="large" type="primary" block htmlType="submit" className="tic-enquiry-modal-btn" loading={loading}>
                  Register for free
                </Button>
              </Form.Item>
              <motion.div
                initial={{ opacity: 0, marginBottom: 0 }}
                animate={{
                  opacity: error ? 1 : 0,
                  marginTop: error ? 20 : 0,
                  marginBottom: error ? 40 : 0,
                }}>
                <Alert type='error' showIcon message={error}></Alert>
              </motion.div>
            </Form>
          </Tabs.TabPane>
        </Tabs>
      </ErrorBoundary>
    </Modal>
  );
};

export default Authentication;
