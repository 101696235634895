import React, { useEffect, useState } from 'react';
import { Typography, Row, Col, Button, Avatar, Form, Input, Modal, message, Alert, Tag, Space } from 'antd';
import './SendEnquiryModal.css';
import { LEAD_URL, UPLOAD_URL } from 'constants/ApiConstants';
import { motion } from 'framer-motion';
import axios from 'axios';
import { isEmpty } from 'lodash';

const { Title, Paragraph } = Typography;

const SendEnquiryModal = ({ listing, product, user, save, close, visible }) => {
  const [form] = Form.useForm();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!visible) {
      setError(null);
      setLoading(false);
      form.resetFields();
    } else {
      form.setFieldsValue({
        name: user?.name,
        email: user?.email,
        phone: user?.phone,
      })
    }
  }, [visible, user]);


  const onSubmit = async (values) => {
    setLoading(true);
    setError(null);
    try {
      const data = {
        name: values.name,
        emailAddress: values.email,
        phoneNumber: values.phone,
        enquiry: values.enquiry,
        business: listing._id,
        product: product? product._id : undefined,
      };
      const res = await axios.post(`${LEAD_URL}`, data);
      if (res.data.status === 'error') {
        setError(res.data.error);
        return setLoading(false);
      }
      message.success(`Successfully placed enquiry for business`);
      save();
    } catch (err) {
      setError(err.message);
    }
    setLoading(false);
  };

  const onClose = () => {
    if (close) close();
  }

  return (
    <Modal open={visible} footer={null} onCancel={onClose} className="tic-enquiry-modal-card">
        <Row justify="space-between" align="middle">
          <Col span={24}>
            <Paragraph className="tic-enquiry-modal-subtitle">
              Get Connected to
            </Paragraph>
            <Title level={5} className="tic-enquiry-modal-title">
              <Space direction="horizontal" size={2}>
                <Avatar src={`${UPLOAD_URL}/${listing?.logo}`} className='tic-enquiry-modal-logo' />
                <Space direction="vertical" size={2}>
                  {listing?.name}
                  {!isEmpty(product) && (<Tag>{product?.name}</Tag>)}
                </Space>
              </Space>
            </Title>
            <Title level={5} className="tic-enquiry-modal-form-title">
              Share your requirement
            </Title>
            <Form form={form} layout="vertical" onFinish={onSubmit}>
              <Form.Item
                name="name"
                rules={[
                  {
                    required: true,
                    type: 'string',
                    whitespace: true,
                    message: 'Please enter your name',
                  },
                ]}
                hasFeedback>
                <Input size="small" placeholder="Name" className='tic-enquiry-modal-input' />
              </Form.Item>
              <Form.Item
                name="email"
                rules={[
                  {
                    required: true,
                    type: 'email',
                    message: 'Please enter a valid email address',
                  },
                ]}
                hasFeedback>
                <Input size="small" placeholder="Email Address" className='tic-enquiry-modal-input'  />
              </Form.Item>
              <Form.Item
                name="phone"
                rules={[
                  {
                    required: true,
                    message: 'Please enter your phone number',
                  },
                  {
                    pattern: /^(\+91|0)?[789]\d{9}$/,
                    message: 'Please enter a valid phone number',
                  },
                ]}
                hasFeedback>
                <Input size="small" placeholder="Phone Number" className='tic-enquiry-modal-input' />
              </Form.Item>
              <Form.Item
                name="enquiry"
                rules={[
                  {
                    required: true,
                    type: 'string',
                    whitespace: true,
                    message: 'Please enter your requirement',
                  },
                ]}
                hasFeedback>
                <Input.TextArea size="small" placeholder="Type your requirement..." className='tic-enquiry-modal-textarea' rows={4} />
              </Form.Item>
              <Form.Item>
                <Button size="large" type="primary" block htmlType="submit" className="tic-enquiry-modal-btn" loading={loading}>
                  Submit Enquiry
                </Button>
              </Form.Item>
              <motion.div
                initial={{ opacity: 0, marginBottom: 0 }}
                animate={{
                  opacity: error ? 1 : 0,
                  marginTop: error ? 20 : 0,
                  marginBottom: error ? 40 : 0,
                }}>
                <Alert type='error' showIcon message={error}></Alert>
              </motion.div>
            </Form>
          </Col>
        </Row>
    </Modal>
  );
};

export default SendEnquiryModal;
